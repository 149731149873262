.spring-2022 {
    font-family: Gemunu Libre;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.32em;
    text-align: center;
    color: #AAFF3E;
    text-transform: uppercase;
    border-color: #AAFF3E;
    border: 4px solid;
    border-radius: 48px;


    }
