@import url(https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600&display=swap);
/* body {
  margin: 0;
  font-family: 'Jura', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*testing commit
and another commit

/* .App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #aaff3e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #070707;
}

.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* .App {
  background-color: rgb(43, 43, 47)
} */
body {
  width: 100%;
  overflow-x: hidden;
  font-family: Jura;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  background-color: black;
}
h2{
  font-family: Gemunu Libre;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
h4 {
  font-family: Gemunu Libre;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-align: center;
}

.App {
  width: 100%;
  overflow-x: hidden;
}

.hero-container {
  padding-top: 6rem;
}

.navbar-brand svg:hover {
  fill: #898996;
  transition: all .2s ease-in;
}

.footer-social svg:hover {
  fill: #A504DE;
  transition: all .2s ease-in;
}

/* styling for mobile hero section */
@media (max-width: 767px) {
  .hero-container {
    width: 100%;
    height: 92vh;
    position: relative;
  }

  .hero-image {
    width: auto;
    height: 92vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    object-fit: scale-down;
    padding: 1rem 1rem 0rem 1rem;
    display: block;
  }

  .hero-logo {
    width: 90% !important;
    padding: 1rem !important;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.spring-2022 {
    font-family: Gemunu Libre;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.32em;
    text-align: center;
    color: #AAFF3E;
    text-transform: uppercase;
    border-color: #AAFF3E;
    border: 4px solid;
    border-radius: 48px;


    }

.join-drop-zero {
font-family: Gemunu Libre;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 24px;
letter-spacing: 0.32em;
text-align: center;
color: #AAFF3E;
text-transform: uppercase;
border: 4px solid #AAFF3E;
border-radius: 48px;

}

.join-drop-zero:hover {
    color: black;
    background-color: #AAFF3E;
    cursor: pointer;
    border: 4px solid #AAFF3E;
    transition: all .1s ease-in;
}

