.join-drop-zero {
font-family: Gemunu Libre;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 24px;
letter-spacing: 0.32em;
text-align: center;
color: #AAFF3E;
text-transform: uppercase;
border: 4px solid #AAFF3E;
border-radius: 48px;

}

.join-drop-zero:hover {
    color: black;
    background-color: #AAFF3E;
    cursor: pointer;
    border: 4px solid #AAFF3E;
    transition: all .1s ease-in;
}
